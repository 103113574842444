import { FormOutlined } from "@ant-design/icons";
import { gql, useQuery } from "@apollo/client";
import { Button, Switch, Table } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import formatProductDescription from "@/functions/format-product-description";

import prepareIssueComment from "../prepare-issue-comment";

function formatProductProps(product: Record<string, any>) {
  return (
    <span>
      <span>{formatProductDescription(product as any)}</span>
      {product.serialCode && <div style={{ fontStyle: "italic" }}>Serienummer: {product.serialCode}</div>}
      {product.productionBatch && <div style={{ fontStyle: "italic" }}>Batchnummer: {product.productionBatch}</div>}
      {product.optionalDescription && <div style={{ fontStyle: "italic" }}>{product.optionalDescription}</div>}
    </span>
  );
}

interface WorksheetItemsTableProps {
  onCreateIssueClick: (values: Record<string, unknown>) => void;
  worksheetId: string;
}

const workTypes = [
  { value: "installproductwork", text: "Installatie" },
  { value: "serviceproductwork", text: "Onderhoud" },
  { value: "registerproductwork", text: "Registratie" },
  { value: "replaceproductwork", text: "Vervanging" },
  { value: "discardproductwork", text: "Verwijdering" },
];

export default function WorksheetItemsTable({ onCreateIssueClick, worksheetId }: WorksheetItemsTableProps) {
  const { data, loading } = useQuery(WorksheetQuery, { variables: { worksheetId } });
  const worksheet = data?.worksheet ?? undefined;

  const sortedOnActionableItems = React.useMemo(() => {
    const workItems = data?.worksheet.work ?? [];

    return [...workItems].sort((a, b) => {
      const aIsActionableType = a.__typename.toLowerCase() === "installproductwork" || a.__typename.toLowerCase() == "replaceproductwork";
      const bIsActionableType = b.__typename.toLowerCase() === "installproductwork" || b.__typename.toLowerCase() == "replaceproductwork";
      const aHasInternalComment = null !== a.internalComment;
      const bHasInternalComment = null !== b.internalComment;

      if (aIsActionableType === bIsActionableType && aHasInternalComment === bHasInternalComment) return 0;
      return (aIsActionableType && !bIsActionableType) || (aHasInternalComment && !bHasInternalComment) ? -1 : 1;
    });
  }, [data]);

  const columns = React.useMemo(
    () => [
      {
        title: "Type",
        key: "workType",
        render: item => <FormattedMessage id={`worksheet.work.workType.${item.__typename.toLowerCase()}`} />,
        filters: workTypes,
        onFilter: (value, record) => record.__typename.toLowerCase() === value,
      },
      {
        title: "Product",
        key: "productType",
        render: item => (
          <>
            {formatProductProps(item.servicedProduct)}
            {item.previousProduct && (
              <>
                <span style={{ display: "block", margin: "8px 0" }}>vervangt</span>
                <span>{formatProductProps(item.previousProduct)}</span>
              </>
            )}
          </>
        ),
      },
      {
        title: "Hoofdproduct",
        key: "parentProduct",
        render: item => {
          const parentItem = item.parentProduct ?? item.servicedProduct.parent ?? undefined;
          return undefined !== parentItem ? formatProductProps(parentItem) : "-";
        },
      },
      {
        title: "Eigen voorraad",
        key: "isFromOwnStock",
        render: item => (undefined !== item.isFromOwnStock ? (item.isFromOwnStock ? "Ja" : "Nee") : "-"),
      },
      {
        title: "Opmerking intern",
        dataIndex: "internalComment",
        render: internalComment => internalComment ?? "-",
        onFilter: (_value, record) => null !== record.internalComment && record.internalComment.trim().length > 0,
        filterDropdown: ({ confirm, selectedKeys, setSelectedKeys }) => (
          <div className="ant-table-filter-dropdown">
            <div className="ant-dropdown-menu" style={{ padding: 12 }}>
              <span style={{ marginRight: 16 }}>Moet opmerking bevatten</span>
              <Switch
                checked={selectedKeys.length > 0}
                onChange={() => {
                  setSelectedKeys(selectedKeys.length === 0 ? [true] : []);
                  setTimeout(() => confirm(), 200);
                }}
              />
            </div>
          </div>
        ),
      },
      {
        key: "actions",
        render: workItem => {
          const onClick = () => {
            if (undefined === worksheet) return;

            onCreateIssueClick({
              relationId: worksheet.relation.id,
              locationId: worksheet.location.id,
              relatedAppointmentId: worksheet.appointment?.id,
              relatedProducts: [workItem.servicedProduct.id],
              comment: prepareIssueComment(workItem.internalComment, worksheet.employee.username),
            });
          };

          return <Button onClick={onClick} icon={<FormOutlined />} type="link" size="small" />;
        },
      },
    ],
    [worksheet]
  );

  return (
    <Table
      bordered
      columns={columns}
      dataSource={sortedOnActionableItems}
      loading={loading}
      pagination={data?.worksheet.work.length > 10 ? undefined : false}
      rowKey={work => work.id}
      title={() => "Werkzaamheden"}
    />
  );
}

const WorksheetQuery = gql`
  query ($worksheetId: ID!) {
    worksheet(id: $worksheetId) {
      id
      appointment {
        id
      }
      employee {
        id
        username
      }
      relation {
        id
      }
      location {
        id
      }
      work {
        id
        servicedProduct {
          id
          productType {
            id
            code
            description
          }
          parent {
            id
            productType {
              id
              code
              description
            }
            brand
            serialCode
            productionBatch
            expirationDate
            optionalDescription
          }
          brand
          serialCode
          productionBatch
          expirationDate
          optionalDescription
        }
        internalComment
        ... on RegisterProductWork {
          previousProduct {
            id
            productType {
              id
              code
              description
            }
            brand
            serialCode
            productionBatch
            expirationDate
            optionalDescription
          }
        }
        ... on InstallProductWork {
          isFromOwnStock
          parentProduct {
            id
            productType {
              id
              code
              description
            }
            brand
            serialCode
            productionBatch
            expirationDate
            optionalDescription
          }
        }
        ... on ReplaceProductWork {
          isFromOwnStock
          previousProduct {
            id
            productType {
              id
              code
              description
            }
            brand
            serialCode
            productionBatch
            expirationDate
            optionalDescription
          }
        }
      }
    }
  }
`;
