import { CheckOutlined, FileExcelOutlined, FilePdfOutlined, MailOutlined, SnippetsOutlined, SwapOutlined } from "@ant-design/icons";
import { ApolloError, gql, useMutation, useQuery } from "@apollo/client";
import { Button, Card, Descriptions, notification } from "antd";
import dayjs from "dayjs";
import truncate from "lodash/truncate";
import * as React from "react";
import { rrulestr } from "rrule";

import DownloadButton from "@/components/DownloadButton";
import Downloader from "@/components/Downloader";
import MaybePopover from "@/components/MaybePopover";
import { formatAppointmentType } from "@/functions/appointment-util";
import downloadAttachment from "@/functions/download-attachment";
import formatAddress from "@/functions/format-address";
import mapGraphQLErrorsToNotifications from "@/functions/map-graphql-errors-to-notifications";

import MarkWorksheetProcessedMutation from "../graphql/MarkWorksheetProcessedMutation";
import SendWorksheetReportToRelationMutation from "../graphql/SendWorksheetReportToRelationMutation";
import GenerateInvoicePopover from "./GenerateInvoicePopover";
import ReplaceWorksheetFileModal from "./ReplaceWorksheetFileModal";

interface ExtendedVisitInfoProps {
  worksheetId: string;
}

export default function ExtendedVisitInfo({ worksheetId }: ExtendedVisitInfoProps) {
  const [downloadingWithFiles, setDownloadingWithFiles] = React.useState<Array<unknown> | undefined>();
  const [replaceWorkheetModalVisible, setReplaceWorksheetFileModalVisible] = React.useState(false);

  const { data, loading } = useQuery(WorksheetQuery, { variables: { worksheetId } });
  const [markProcessedAsync, { loading: isMarkingProcessed }] = useMutation(MarkWorksheetProcessedMutation, { variables: { worksheetId } });
  // eslint-disable-next-line prettier/prettier
  const [sendReportAsync, { loading: isSendingReport }] = useMutation(SendWorksheetReportToRelationMutation, {
    variables: { worksheetId },
  });

  const actions = [
    <DownloadButton
      key="export-excel"
      icon={<FileExcelOutlined />}
      onClick={() => downloadAttachment("reports/worksheets/" + worksheetId)}
      type="text"
    >
      Exporteer Excel
    </DownloadButton>,
    <Button
      key="export-pdf"
      disabled={data?.worksheet.files?.length < 1}
      icon={<FilePdfOutlined />}
      onClick={() => setDownloadingWithFiles(data?.worksheet.files)}
      type="text"
    >
      {data?.worksheet.files?.length > 0 ? "Bekijk rapportage" : "Niet beschikbaar"}
    </Button>,
    <Button
      key="replace-pdf"
      disabled={data?.worksheet.files?.length < 1}
      onClick={() => setReplaceWorksheetFileModalVisible(true)}
      icon={<SwapOutlined />}
      type="text"
    >
      {data?.worksheet.files?.length > 0 ? "Vervang rapportage" : "Niet beschikbaar"}
    </Button>,
    <MaybePopover key="send-pdf" if={null !== data?.worksheet.reportSentOn} content="Let op! Rapportage is reeds verzonden. Nogmaals?">
      <Button
        icon={<MailOutlined />}
        loading={isSendingReport}
        onClick={async () => {
          try {
            await sendReportAsync();
            notification.success({ message: "Rapportage is verzonden" });
          } catch (error) {
            mapGraphQLErrorsToNotifications(error as ApolloError);
          }
        }}
        type="text"
      >
        Zend rapportage naar klant
      </Button>
    </MaybePopover>,
    <GenerateInvoicePopover key="generate-invoice" disabled={null !== data?.worksheet.invoicedOn} worksheetId={worksheetId}>
      <Button icon={<SnippetsOutlined />} type="text">
        {null !== data?.worksheet.invoicedOn ? "Baliedirectfactuur aangemaakt" : "Genereer baliedirectfactuur"}
      </Button>
    </GenerateInvoicePopover>,
    <Button
      key="mark-processed"
      disabled={null !== data?.worksheet.processedOn}
      loading={isMarkingProcessed}
      icon={<CheckOutlined />}
      onClick={async () => {
        try {
          await markProcessedAsync();
          notification.success({ message: "Gemarkeerd als verwerkt" });
        } catch (error) {
          mapGraphQLErrorsToNotifications(error as ApolloError);
        }
      }}
      type="text"
    >
      {null !== data?.worksheet.processedOn ? "Gemarkeerd als verwerkt" : "Markeer als verwerkt"}
    </Button>,
  ];

  const w = data?.worksheet;
  const v = w?.appointment?.visit;
  const c = v?.contract;
  const flags = (v?.overrideDefaultFlags ? v?.flags : c?.defaultFlags) ?? [];

  return (
    <>
      {/* eslint-disable-next-line prettier/prettier */}
      {undefined !== downloadingWithFiles && <Downloader files={downloadingWithFiles} onClose={() => setDownloadingWithFiles(undefined)} />}
      {undefined !== data && replaceWorkheetModalVisible && (
        <ReplaceWorksheetFileModal onClose={() => setReplaceWorksheetFileModalVisible(false)} worksheetId={worksheetId} />
      )}
      <Card
        actions={undefined === data ? undefined : actions}
        loading={loading}
        title="Algemene gegevens"
        bodyStyle={{ padding: 0, margin: "0 -1px -1px -1px" }}
      >
        {undefined !== w && (
          <>
            <Descriptions bordered layout="horizontal">
              <Descriptions.Item label="Relatie">
                ({w.relation.afasCode}) {w.relation.name}
              </Descriptions.Item>
              <Descriptions.Item label="Locatie">
                <span style={{ display: "block" }}>{w.location.name}</span>
                <em style={{ display: "block", marginTop: 4 }}>
                  {truncate(formatAddress(w.location.address), { length: 75, separator: /,? +/ })}
                </em>
              </Descriptions.Item>
              <Descriptions.Item label="Datum">
                {`${dayjs(w.createdOn).format("LL")} ${dayjs(w.createdOn).format("HH:mm")} - ${dayjs(w.completedOn).format("HH:mm")}`}
              </Descriptions.Item>
              {w.relation.belongsTo && (
                <Descriptions.Item label="Dealer-klant">
                  {`(${w.relation.belongsTo.afasCode}) ${w.relation.belongsTo.name}`}
                </Descriptions.Item>
              )}
              <Descriptions.Item label="Soort afspraak">
                {w.appointment ? formatAppointmentType(w.appointment.appointmentType) : "-"}
              </Descriptions.Item>
              <Descriptions.Item label="Monteur">{w.employee.username}</Descriptions.Item>
              <Descriptions.Item label="Frequentie">{v?.recurrence ? rrulestr(v?.recurrence).toText() : "-"}</Descriptions.Item>
              <Descriptions.Item label="Inclusief disposables">
                {v !== undefined ? (flags.includes("FLAG_INCLUDES_DISPOSABLES") ? "Ja" : "Nee") : "-"}
              </Descriptions.Item>
              <Descriptions.Item label="Onderhoudsbeurt los factureren (geen abonnement)">
                {v !== undefined ? (flags.includes("FLAG_INVOICE_AFTERWARDS") ? "Ja" : "Nee") : "-"}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions bordered column={2} layout="horizontal" style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
              {w.relation.planningComment && (
                <Descriptions.Item label="Planning notities (relatie)" span={2}>
                  {w.relation.planningComment}
                </Descriptions.Item>
              )}
              {w.relation.onSiteComment && (
                <Descriptions.Item label="Buitendienst notities (relatie)" span={2}>
                  {w.relation.onSiteComment}
                </Descriptions.Item>
              )}
              {w.relation.invoiceComment && (
                <Descriptions.Item label="Facturatie notities (relatie)" span={2}>
                  {w.relation.invoiceComment}
                </Descriptions.Item>
              )}
              {w.location.planningComment && (
                <Descriptions.Item label="Planning notities (locatie)" span={2}>
                  {w.relation.planningComment}
                </Descriptions.Item>
              )}
              {w.location.onSiteComment && (
                <Descriptions.Item label="Buitendienst notities (relatie)" span={2}>
                  {w.location.onSiteComment}
                </Descriptions.Item>
              )}
              {w.location.invoiceComment && (
                <Descriptions.Item label="Facturatie notities (locatie)" span={2}>
                  {w.location.invoiceComment}
                </Descriptions.Item>
              )}
              {w.appointment?.plannerComment && (
                <Descriptions.Item label="Planning notities (afspraak)">{w.appointment.plannerComment}</Descriptions.Item>
              )}
              {data.worksheet.appointment?.onSiteComment && (
                <Descriptions.Item label="Buitendienst notities (afspraak)" span={2}>
                  {w.appointment.onSiteComment}
                </Descriptions.Item>
              )}
              {w.publicComment && <Descriptions.Item label="Opmerking publiek">{w.publicComment}</Descriptions.Item>}
              {w.internalComment && <Descriptions.Item label="Opmerking intern">{w.internalComment}</Descriptions.Item>}
            </Descriptions>
          </>
        )}
      </Card>
    </>
  );
}

const WorksheetQuery = gql`
  query ($worksheetId: ID!) {
    worksheet(id: $worksheetId) {
      id
      createdOn
      completedOn
      processedOn
      invoicedOn
      relation {
        id
        afasCode
        name
        invoiceComment
        belongsTo {
          id
          afasCode
          name
        }
      }
      location {
        id
        name
        invoiceComment
        address {
          street
          postalCode
          city
          country
        }
      }
      appointment {
        id
        appointmentType {
          id
          name
          category {
            id
            name
          }
        }
        visit {
          id
          recurrence
          overrideDefaultFlags
          flags
          contract {
            id
            defaultFlags
          }
        }
        plannerComment
        onSiteComment
      }
      employee {
        id
        username
      }
      files {
        id
        name
      }
      publicComment
      internalComment
    }
  }
`;
